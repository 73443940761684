<template>
  <div class="buyAddress">
    <div class="cell">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div>我的地址</div>
        <div>
          <el-button
            @click="add"
            type="primary"
            icon="el-icon-plus"
            size="medium"
            >新增地址</el-button
          >
        </div>
      </div>
      <div class="miaosu"></div>
      <div v-if="httpShow">
        <div v-if="list.list.length != 0">
          <el-radio-group
            v-model="$store.state.addressRadio"
            style="width: 100%"
            @change="handleChanges"
          >
            <div class="list" v-for="(item, index) in list.list" :key="item.id">
              <div
                style="display: flex; align-items: center; padding-left: 20px"
              >
                <el-radio v-if="show" :label="item.id">&emsp;</el-radio>
                <div style="padding-right:20px">
                  <div
                    style="
                      display: flex;
                      padding-left: 100px;
                      margin-bottom: 10px;
                      align-items: center;
                    "
                  >
                    <div style="margin-right: 20px">全名</div>
                    <div>
                      <span style="margin-right: 10px">
                        {{ item.name }}
                      </span>
                      <el-tag
                        v-if="item.default_addr == 1"
                        effect="dark"
                        size="medium"
                        color="#00BFA5"
                        style="border-color: #00bfa5; margin-right: 10px"
                        :hit="false"
                        >默认</el-tag
                      >
                      <el-tag
                        effect="plain"
                        size="medium"
                        v-if="item.sign != 0"
                        >{{ item.sign == 1 ? "住宅" : "工作" }}</el-tag
                      >
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      padding-left: 100px;
                      margin-bottom: 10px;
                    "
                  >
                    <div style="margin-right: 20px">手机</div>
                    <div>{{ item.mobile }}</div>
                  </div>
                  <div
                    style="
                      display: flex;
                      padding-left: 100px;
                      margin-bottom: 10px;
                    "
                  >
                    <div style="margin-right: 20px">地址</div>
                    <div>
                      <div>{{ item.address }}</div>
                      <div style="margin: 4px 0">
                        {{ item.city }},{{ item.district }}
                      </div>
                      <div>
                        {{ item.zip_code }}
                        <span></span>
                        {{ item.province }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="margin-bottom: 20px;min-width:96px">
                  <span class="text" @click="edit(item)">编辑</span>
                  <span class="text" @click="handleDel(item.id)">删除</span>
                </div>
                <div v-if="item.default_addr != 1">
                  <el-button plain size="small" @click="handelDef(index)"
                    >设定为默认</el-button
                  >
                </div>
              </div>
            </div>
          </el-radio-group>
        </div>
        <div v-else>
          <el-empty description="您还没有添加地址"></el-empty>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增地址"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
    >
      <div id="box" style="height: 400px; padding: 0 10px">
        <el-form
          label-position="top"
          label-width="80px"
          :model="formLabelAlign"
          inline
          ref="resetForm"
        >
          <el-form-item prop="name" style="width: 48%">
            <el-input
              v-model="formLabelAlign.name"
              placeholder="全名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="mobile" style="width: 48%">
            <el-input v-model="formLabelAlign.mobile" placeholder="电话号码">
            </el-input>
          </el-form-item>
          <div>
            <el-form-item style="width: 98%">
              <!-- <el-input v-model="formLabelAlign.city_code"></el-input> -->
              <el-cascader
                v-if="acc"
                style="width: 100%"
                v-model="value"
                :options="options"
                @change="handleChange"
                placeholder="城市，地区"
              ></el-cascader>
              <el-cascader
                v-if="!acc && value.length != 0"
                style="width: 100%"
                v-model="value"
                :options="options"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
          </div>
          <el-form-item prop="zip_code" style="width: 98%">
            <el-input
              v-model="formLabelAlign.zip_code"
              placeholder="邮政编码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="address" style="width: 98%">
            <el-input
              type="textarea"
              v-model="formLabelAlign.address"
              placeholder="门牌号，建筑，街名"
            ></el-input>
          </el-form-item>
          <div style="margin-bottom: 10px">
            <div style="padding-bottom: 20px">标注为：</div>
            <span
              @click="handleClick(1)"
              class="btn"
              :class="formLabelAlign.sign == 1 ? 'active' : ''"
              >住宅</span
            ><span
              @click="handleClick(2)"
              class="btn"
              :class="formLabelAlign.sign == 2 ? 'active' : ''"
              >工作</span
            >
          </div>

          <el-checkbox
            style="margin: 10px 0"
            v-model="formLabelAlign.default_addr"
            :true-label="1"
            :false-label="0"
            >设为预设地址</el-checkbox
          >
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveAddress">完成</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getBAuth } from "@/utils/auth.js";
import areaList from "@/assets/js/areaList.js";
import { addressList, operateAddress, deleteAddress } from "@/api/buy.js";
export default {
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      dialogVisible: false,
      acc: false,
      value: [],
      formLabelAlign: {
        id: "",
        name: "",
        mobile: "",
        address: "",
        zip_code: "",
        default_addr: "",
        province: "",
        city: "",
        district: "",
        province_code: "",
        city_code: "",
        district_code: "",
        sign: 0,
      },
      options: [],
      list: [],
      httpShow: false,
    };
  },
  mounted() {
    if (!getBAuth()) {
      this.$router.replace("buyLogin");
    }
  },
  created() {
    this.$store.state.buyMenuIndex = 12;
    this.getArea();
    this.getList();
  },
  methods: {
    getList() {
      addressList().then((res) => {
        if (res.code == 1) {
          this.list = res.data;
          this.httpShow = true;
          this.radio = this.list.list[0].id;
        }
      });
    },
    saveAddress() {
      operateAddress({ ...this.formLabelAlign }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.handleClose();
          this.getList();
        }
      });
    },
    handleClose() {
      this.acc = false;
      this.formLabelAlign = {
        id: "",
        name: "",
        mobile: "",
        address: "",
        zip_code: "",
        default_addr: "",
        province: "",
        city: "",
        district: "",
        province_code: "",
        city_code: "",
        district_code: "",
        sign: 0,
      };
      this.value = [];
      this.dialogVisible = false;
      this.acc = false;
    },
    getArea() {
      Object.keys(areaList.province_list).forEach((item, i) => {
        this.options[i] = {
          value: item + "," + areaList.province_list[item],
          label: areaList.province_list[item],
          children: [],
        };
        Object.keys(areaList.city_list).forEach((val) => {
          let cityId = item.slice(0, 2);
          if (cityId == val.slice(0, 2)) {
            this.options[i].children.push({
              value: val + "," + areaList.city_list[val],
              label: areaList.city_list[val],
              children: [],
            });
          }
        });
      });
      this.options.forEach((item) => {
        item.children.forEach((val) => {
          Object.keys(areaList.county_list).forEach((vals) => {
            let cityId = val.value.slice(0, 4);
            if (cityId == vals.slice(0, 4)) {
              val.children.push({
                value: vals + "," + areaList.county_list[vals],
                label: areaList.county_list[vals],
              });
            }
          });
        });
      });
    },
    handleChange(value) {
      this.formLabelAlign.province_code = value[0].split(",")[0];
      this.formLabelAlign.province = value[0].split(",")[1];
      this.formLabelAlign.city_code = value[1].split(",")[0];
      this.formLabelAlign.city = value[1].split(",")[1];
      this.formLabelAlign.district_code = value[2].split(",")[0];
      this.formLabelAlign.district = value[2].split(",")[1];
    },
    add() {
      this.dialogVisible = true;
      this.acc = true;
    },
    edit(value) {
      this.dialogVisible = true;
      this.formLabelAlign = JSON.stringify(value);
      this.formLabelAlign = JSON.parse(this.formLabelAlign);
      this.value[0] =
        this.formLabelAlign.province_code + "," + this.formLabelAlign.province;
      this.value[1] =
        this.formLabelAlign.city_code + "," + this.formLabelAlign.city;
      this.value[2] =
        this.formLabelAlign.district_code + "," + this.formLabelAlign.district;
    },
    handelDef(index) {
      this.formLabelAlign = this.list.list[index];
      this.value[0] =
        this.formLabelAlign.province_code + "," + this.formLabelAlign.province;
      this.value[1] =
        this.formLabelAlign.city_code + "," + this.formLabelAlign.city;
      this.value[2] =
        this.formLabelAlign.district_code + "," + this.formLabelAlign.district;
      this.formLabelAlign.default_addr = 1;
      this.saveAddress();
    },
    handleClick(index) {
      this.formLabelAlign.sign = index;
    },
    handleDel(id) {
      this.$confirm("删除地址?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAddress({
            id: id,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.message);
              this.getList();
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    handleChanges(val) {
      this.$store.state.addressRadio = val;
      this.list.list.forEach((item) => {
        if (val == item.id) {
          this.$store.state.addressData = item;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.buyAddress {
  padding: 20px;
  .cell {
    background: #fff;
    padding: 30px;
    color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    .miaosu {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin: 6px 0 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #bebebe;
    }
    .list {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px solid #bebebe;
      padding: 30px 0;
      .text {
        cursor: pointer;
        text-decoration: underline;
        margin-right: 20px;
      }
    }
  }
}
.btn {
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #000;
  border: 1px solid #eee;
  margin-right: 10px;
  cursor: pointer;
}
.active {
  color: #ee4d2d;
  border: 1px solid #ee4d2d;
}
</style>