<template>
  <div class="buyPay">
    <div class="main-card">
      <div class="cell">
        <div class="address">
          <div style="display: flex; align-items: center; margin-bottom: 20px">
            <svg
              t="1655697674645"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2133"
              width="20"
              height="20"
            >
              <path
                d="M895.616384 347.812188q0 10.22977-0.511489 19.436563t-1.534466 19.436563q-9.206793 84.907093-37.338661 163.164835t-71.096903 150.377622-99.228771 138.613387-121.734266 127.872128q-9.206793 11.252747-23.528472 11.252747-15.344655 0-24.551449-11.252747-65.470529-61.378621-122.245754-128.895105t-100.251748-141.170829-71.608392-152.935065-36.315684-165.210789q0-8.183816-0.511489-15.344655t-0.511489-15.344655q0-71.608392 28.131868-135.032967t76.211788-110.481518 113.038961-74.677323 138.613387-27.62038 138.101898 27.62038 112.527473 74.677323 76.211788 110.481518 28.131868 135.032967zM540.643357 507.396603q33.758242 0 63.424575-12.787213t51.66034-34.26973 34.781219-50.637363 12.787213-61.89011-12.787213-61.89011-34.781219-50.637363-51.66034-34.26973-63.424575-12.787213-63.424575 12.787213-52.171828 34.26973-35.292707 50.637363-12.787213 61.89011 12.787213 61.89011 35.292707 50.637363 52.171828 34.26973 63.424575 12.787213z"
                p-id="2134"
                fill="#ee4d2d"
              ></path>
            </svg>
            <span style="color: #ee4f2d">收件地址</span>
          </div>
          <div v-if="goodsData.addressData != null">
            <span
              >取货地址（{{ goodsData.addressData.mobile_prefix }}）{{
                goodsData.addressData.mobile
              }}</span
            >
            {{ goodsData.addressData.country
            }}{{ goodsData.addressData.province }},{{
              goodsData.addressData.city
            }}{{ goodsData.addressData.district }},{{
              goodsData.addressData.zip_code
            }}{{ goodsData.addressData.district }},{{
              goodsData.addressData.address
            }}<span
              @click="openAddress"
              style="margin-left: 40px; color: #0055aa; cursor: pointer"
              >更改</span
            >
          </div>
          <div v-else>
            <span
              @click="openAddress"
              style="margin-left: 40px; color: #0055aa; cursor: pointer"
              >管理收货地址</span
            >
          </div>
        </div>
        <div style="box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1)">
          <div class="order">
            <div class="title">
              <div style="color: #222; width: 55%; font-size: 16px">
                订单商品
              </div>
              <div style="width: 20%; text-align: center">单价</div>
              <div style="width: 15%; text-align: center">数量</div>
              <div style="width: 15%; text-align: right">商品小计</div>
            </div>
          </div>
          <div
            v-for="(item, i) in goodsData.orderData"
            :key="i"
            style="margin-top: 10px"
          >
            <div
              style="padding: 0 40px; padding-bottom: 20px; background: #fff"
            >
              <div class="shop-name">
                {{ item.shop_name }}
              </div>
              <div
                class="item"
                v-for="(val, index) in item.products"
                :key="index"
              >
                <div
                  style="
                    color: #222;
                    width: 55%;
                    display: flex;
                    align-items: center;
                  "
                >
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="val.image"
                    fit="fit"
                  ></el-image>
                  <span style="margin: 0 10px; font-size: 14px"
                    >{{ val.title }}
                    {{
                      val.difference ? ",[" + val.difference + "]" : ""
                    }}</span
                  >
                </div>
                <div style="width: 20%; text-align: center">
                  <span
                    v-if="val.discount_ration != 0"
                    style="
                      text-decoration: line-through;
                      color: #b0b0b0;
                      margin-right: 20px;
                    "
                  >
                    RM{{ val.fprice }}
                  </span>
                  RM{{ val.price }}
                </div>
                <div style="width: 15%; text-align: center">
                  {{ val.number }}
                </div>
                <div
                  v-if="val.discount_number != 0"
                  style="width: 15%; text-align: right"
                >
                  RM{{
                    val.number > val.discount_number
                      ? (
                          val.fprice * (val.number - val.discount_number) +
                          val.price * val.discount_number
                        ).toFixed(2)
                      : (val.price * val.number).toFixed(2)
                  }}
                </div>
                <div v-else style="width: 15%; text-align: right">
                  RM{{ val.price * val.number }}
                </div>
              </div>
            </div>
            <div class="price">
              <div class="freight">
                <div class="left">
                  <span
                    style="font-size: 14px; margin-right: 10px; color: #9c9e9f"
                    >讯息：</span
                  >
                  <el-input
                    style="max-width: 300px"
                    v-model="item.note"
                    placeholder="（选填）留言给卖家"
                  ></el-input>
                </div>
                <div class="right">
                  <span style="font-size: 14px; color: #00bfa5"
                    >运送资讯：</span
                  >
                  <div>
                    <div style="font-size: 14px; color: #363737">海外直运</div>
                    <div style="margin: 4px 0">
                      {{ item.products[0].freight.name }}
                    </div>
                    <div>到货时间4月30日-5月9日</div>
                  </div>
                  <el-popover
                    @show="getLogistics(item)"
                    placement="top"
                    width="200"
                    trigger="click"
                    v-model="item.show"
                  >
                    <div>
                      <div style="margin-bottom: 10px">选择物流商：</div>
                      <el-radio-group
                        @change="qhwl($event, item)"
                        v-model="radio"
                      >
                        <div
                          style="padding-bottom: 10px"
                          v-for="item in wlList"
                          :key="item.id"
                        >
                          <el-radio :label="item.id">{{ item.name }}</el-radio>
                        </div>
                      </el-radio-group>
                    </div>
                    <div
                      style="color: #2772bb; cursor: pointer"
                      slot="reference"
                    >
                      更改
                    </div>
                  </el-popover>

                  <div>RM{{ item.total_freight }}</div>
                </div>
              </div>
              <div class="combined">
                <span>订单总额({{ item.number }}个商品)：</span>
                <span style="color: #ee4d2d; font-size: 22px;margin-"
                  >RM{{ item.zjg }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="way">
          <div
            style="
              background: #fff;
              display: flex;
              align-items: center;
              padding: 20px 40px;
            "
          >
            <div style="margin-right: 80px">付款方式</div>
            <div style="display: flex; align-items: center">
              <div
                class="btns"
                :class="index == 0 ? 'actives' : ''"
                @click="handleQH(0)"
              >
                货到付款
                <div class="float" v-if="index == 0">
                  <i
                    class="el-icon-check"
                    style="
                      color: #fff;
                      position: absolute;
                      left: -10px;
                      bottom: -20px;
                    "
                  ></i>
                </div>
              </div>
              <div
                class="btns"
                :class="index == 1 ? 'actives' : ''"
                @click="handleQH(1)"
              >
                虚拟支付
                <div class="float" v-if="index == 1">
                  <i
                    class="el-icon-check"
                    style="
                      color: #fff;
                      position: absolute;
                      left: -10px;
                      bottom: -20px;
                    "
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="stat">
            <div class="r">
              <div class="item">
                <div>商品小计:</div>
                <div>RM{{ totalPrice }}</div>
              </div>
              <div class="item" v-if="couponsPrice != 0">
                <div>优惠券折扣:</div>
                <div>RM{{ couponsPrice }}</div>
              </div>
              <div class="item" v-if="discountPrice != 0">
                <div>商品折扣:</div>
                <div>RM{{ discountPrice }}</div>
              </div>
              <div class="item" v-if="suitPrice != 0">
                <div>套装优惠:</div>
                <div>RM{{ suitPrice }}</div>
              </div>
              <div class="item">
                <div>总运费:</div>
                <div>RM{{ totalFreight }}</div>
              </div>
              <div class="item">
                <div>付款总额:</div>
                <div style="color: #ee4d2d; font-size: 22px">
                  RM{{ totalPriceZ }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: right;
              background: #fffefb;
              padding: 14px 20px;
            "
          >
            <el-button
              @click="handleAddOrder"
              type="primary"
              style="min-width: 200px"
              >下订单</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="更改地址"
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleClosea"
    >
      <buyAddress :show="true" style="padding: 0"></buyAddress>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleXzdz">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addOrder,
  addressList,
  operateAddress,
  // deleteAddress,
  getOrderGoodsList,
  payment,
} from "@/api/buy.js";
import { logisticsLists } from "@/api/common.js";
import areaList from "@/assets/js/areaList.js";
import buyAddress from "@/views/buyer/buyAddress.vue";
export default {
  components: {
    buyAddress,
  },
  data() {
    return {
      index: 0,
      goodsData: "",
      totalPrice: 0,
      totalFreight: 0,
      totalPriceZ: 0,
      couponsPrice: "0.00", //优惠券总折扣
      discountPrice: "0.00", //商品折扣
      suitPrice: "0.00",
      type: 0,

      dialogVisible: false,

      dialogVisible1: false,
      acc: false,
      value: [],
      formLabelAlign: {
        id: "",
        name: "",
        mobile: "",
        address: "",
        zip_code: "",
        default_addr: "",
        province: "",
        city: "",
        district: "",
        province_code: "",
        city_code: "",
        district_code: "",
        sign: 1,
      },
      options: [],
      list: [],
      httpShow: false,
      radio: 3,
      wlList: "",
    };
  },
  created() {
    this.getArea();
    this.getList();
    this.getOrder();
  },
  methods: {
    getOrder() {
      this.totalPrice = 0;
      this.totalFreight = 0;
      let arr = JSON.parse(sessionStorage.PAYGOODSLIST);
      getOrderGoodsList({
        data: arr,
      }).then((res) => {
        if (res.code == 1) {
          res.data.orderData.forEach((item) => {
            item.products.forEach((val) => {
              if (val.discount_ration != 0) {
                val.fprice = val.price;
                val.price = (
                  parseFloat(val.price) *
                  (parseInt(val.discount_ration) / 100)
                ).toFixed(2);
              }
            });
            item.show = false;
            // 4.优惠券折扣
            this.couponsPrice = (
              parseFloat(this.couponsPrice) + parseFloat(item.coupon_price)
            ).toFixed(2);

            // 5.商品折扣
            this.discountPrice = (
              parseFloat(this.discountPrice) + parseFloat(item.discount_price)
            ).toFixed(2);
            // 5.套装优惠
            this.suitPrice = (
              parseFloat(this.suitPrice) + parseFloat(item.suit_price)
            ).toFixed(2);
            // 商品金额+运费
            item.zjg = (
              parseFloat(item.sub_price) + parseFloat(item.total_freight)
            ).toFixed(2);
            // 减去优惠
            item.zjg = (
              parseFloat(item.zjg) -
              parseFloat(item.coupon_price) -
              parseFloat(item.discount_price) -
              parseFloat(item.suit_price)
            ).toFixed(2);
            // 商品小计
            this.totalPrice = (
              parseFloat(this.totalPrice) + parseFloat(item.sub_price)
            ).toFixed(2);
            // 总运费
            this.totalFreight = (
              parseFloat(this.totalFreight) + parseFloat(item.total_freight)
            ).toFixed(2);
            this.totalPriceZ = (
              parseFloat(this.totalPriceZ) + parseFloat(item.zjg)
            ).toFixed(2);
            item.note = "";
          });
          this.goodsData = res.data;
          if (this.goodsData.addressData) {
            this.$store.state.addressRadio = this.goodsData.addressData.id;
            this.$store.state.addressData = this.goodsData.addressData;
          }

          this.type = this.$route.query.type;
        }
      });
    },
    getLogistics(val) {
      val.show = true;
      this.radio = val.products[0].freight.id;
      logisticsLists({
        shop_id: val.shop_id,
      }).then((res) => {
        if (res.code == 1) {
          this.wlList = res.data;
        }
      });
    },
    qhwl(e, val) {
      this.wlList.forEach((item) => {
        if (item.id == e) {
          val.products[0].freight.id = e;
          val.products[0].freight.name = item.name;
        }
      });
      val.show = false;
    },
    handleQH(num) {
      this.index = num;
    },
    handleAddOrder() {
      let arr = JSON.parse(sessionStorage.PAYGOODSLIST);
      let arr1 = [];
      let arr2 = [];
      arr.forEach((item) => {
        arr2.push(item.sku_id);
      });
      this.goodsData.orderData.forEach((item, index) => {
        arr1.push({
          shop_id: item.shop_id,
          products: [],
          remark: item.note,
          coupon_id: item.coupon_id,
          suit_id: item.suit_id,
          freight_id: item.products[0].freight.id,
        });
        item.products.forEach((val) => {
          arr1[index].products.push({
            goods_id: val.id,
            number: val.number,
            sku_id: val.sku_id,
            discount_id: val.discount_id,
            discount_type: val.discount_type,
          });
        });
      });
      let obj = {
        type: this.type,
        address_id: this.$store.state.addressRadio,
        ids: arr2.join(","),
        lists: arr1,
        pay_type: this.index,
      };

      addOrder(obj).then((res) => {
        let arr = [];
        if (res.code == 1) {
          if (this.index == 0) {
            this.$router.replace({ path: "payOk" });
          } else {
            res.data.forEach((item) => {
              arr.push(item.order_id);
            });
            payment({
              order_id: arr.join(","),
            }).then((res) => {
              if (res.code == 1) {
                this.$router.replace({ path: "payOk" });
              }
            });
          }
        }
      });
    },
    openAddress() {
      if (this.goodsData.addressData) {
        this.$store.state.addressRadio = this.goodsData.addressData.id;
      }
      this.dialogVisible = true;
    },
    getList() {
      addressList().then((res) => {
        if (res.code == 1) {
          this.list = res.data;
          this.httpShow = true;
        }
      });
    },
    getArea() {
      Object.keys(areaList.province_list).forEach((item, i) => {
        this.options[i] = {
          value: item + "," + areaList.province_list[item],
          label: areaList.province_list[item],
          children: [],
        };
        Object.keys(areaList.city_list).forEach((val) => {
          let cityId = item.slice(0, 2);
          if (cityId == val.slice(0, 2)) {
            this.options[i].children.push({
              value: val + "," + areaList.city_list[val],
              label: areaList.city_list[val],
              children: [],
            });
          }
        });
      });
      this.options.forEach((item) => {
        item.children.forEach((val) => {
          Object.keys(areaList.county_list).forEach((vals) => {
            let cityId = val.value.slice(0, 4);
            if (cityId == vals.slice(0, 4)) {
              val.children.push({
                value: vals + "," + areaList.county_list[vals],
                label: areaList.county_list[vals],
              });
            }
          });
        });
      });
    },
    handelDef(index) {
      this.formLabelAlign = this.list.list[index];
      this.value[0] =
        this.formLabelAlign.province_code + "," + this.formLabelAlign.province;
      this.value[1] =
        this.formLabelAlign.city_code + "," + this.formLabelAlign.city;
      this.value[2] =
        this.formLabelAlign.district_code + "," + this.formLabelAlign.district;
      this.formLabelAlign.default_addr = 1;
      this.saveAddress();
    },
    saveAddress() {
      operateAddress({ ...this.formLabelAlign }).then((res) => {
        if (res.code == 1) {
          // this.$message.success(res.message);
          this.handleClose();
          this.getList();
        }
      });
    },
    add() {
      this.dialogVisible1 = true;
      this.acc = true;
    },
    handleChange(value) {
      this.formLabelAlign.province_code = value[0].split(",")[0];
      this.formLabelAlign.province = value[0].split(",")[1];
      this.formLabelAlign.city_code = value[1].split(",")[0];
      this.formLabelAlign.city = value[1].split(",")[1];
      this.formLabelAlign.district_code = value[2].split(",")[0];
      this.formLabelAlign.district = value[2].split(",")[1];
    },
    handleClose() {
      this.acc = false;
      this.formLabelAlign = {
        id: "",
        name: "",
        mobile: "",
        address: "",
        zip_code: "",
        default_addr: "",
        province: "",
        city: "",
        district: "",
        province_code: "",
        city_code: "",
        district_code: "",
        sign: 1,
      };
      this.value = [];
      this.dialogVisible1 = false;
      this.acc = false;
    },
    handleClosea() {
      this.dialogVisible = false;
    },
    handleXzdz() {
      this.dialogVisible = false;
      this.goodsData.addressData = this.$store.state.addressData;
    },
  },
};
</script>
<style lang="less" scoped>
.buyPay {
  background: #f5f5f5;
  padding: 20px 0;
  .cell {
    .address {
      background: #fff;
      padding: 20px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
    .order {
      background: #fff;
      padding: 20px 40px 20px;
      margin-top: 16px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      .title {
        display: flex;
        align-items: center;
        color: #bbbbbb;
        font-size: 14px;
      }
    }
    .shop-name {
      padding: 20px 0 20px;
      font-size: 14px;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
    }
    .price {
      width: 100%;
      background: #fafdff;
      .freight {
        border-top: 1px dashed #eee;
        border-bottom: 1px dashed #eee;
        display: flex;
        padding: 0 40px;
        .left {
          border-right: 1px dashed #eee;
          display: flex;
          align-items: center;
          width: 40%;
          padding-top: 20px;
          padding-bottom: 30px;
        }
        .right {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          font-size: 12px;
          color: #888;
        }
      }
      .combined {
        display: flex;
        justify-content: right;
        align-items: center;
        padding: 20px;
        font-size: 14px;
        color: #929292;
      }
    }
    .way {
      margin-top: 16px;
      .btns {
        width: 80px;
        line-height: 37px;
        text-align: center;
        border: 1px solid #eee;
        margin: 0 10px;
        cursor: pointer;
        font-size: 14px;
        border-radius: 2px;
        position: relative;
        overflow: hidden;
        .float {
          width: 0;
          height: 0;
          border: 18px solid transparent;
          border-bottom-color: #ee4d2d;
          position: absolute;
          right: -18px;
          bottom: 0;
        }
      }

      .actives {
        border: 1px solid #ee4d2d;
        color: #ee4d2d;
      }
      .stat {
        display: flex;
        justify-content: right;
        background: #fffefb;
        border-top: 1px solid #eee;
        border-bottom: 1px dashed #eee;
        .r {
          min-width: 200px;
          font-size: 14px;
          color: #777775;
          padding-right: 20px;
          .item {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }

  .buyAddress {
    // padding: 20px;
    .cell {
      background: #fff;
      padding: 30px;
      color: rgba(0, 0, 0, 0.7);
      // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      .miaosu {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        margin: 6px 0 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #bebebe;
      }
      .list {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #bebebe;
        padding: 30px 0;
        .text {
          cursor: pointer;
          text-decoration: underline;
          margin-right: 20px;
        }
      }
    }
  }
  .btn {
    padding: 8px;
    border-radius: 6px;
    font-size: 12px;
    color: #000;
    border: 1px solid #eee;
    margin-right: 10px;
    cursor: pointer;
  }
  .active {
    color: #ee4d2d;
    border: 1px solid #ee4d2d;
  }
}
</style>